/* eslint-disable max-len */
import React, { useCallback, useMemo } from 'react';
import isEqual from 'lodash/isEqual';
import { useIsTestDataCreator, useDebouncedCallback, useState, useUIStore, useZccService, useAuth } from 'utils/hooks';
import { ZCCApi, ZrefApi } from 'apis';
import { getOptionsFromEnum } from 'apis/medical/helper';
import Tooltip from '@mui/material/Tooltip';
import { AppointmentStatusType, ServiceRequestStatusType, CompositionStatusType } from 'apis/medical';
import { RequestStatusType } from 'apis/zref';
import { ZccTaskType, ZCCSortByParameters } from 'apis/zcc';
import { SectionLayout, Table, Button, IconMenu, Icon, FilterButtonV3, TextField } from 'components';
import AppointmentSidePanel from 'components/sidePanels/Appointment';
import ServiceRequestSidePanel from 'components/sidePanels/ServiceRequest';
import moment from 'moment';
import styles from './style.module.scss';
import { FilterType } from 'components/FilterButtonV3/FilterButtonV3.types';
import { getLocalTimezone } from 'utils/helper';
import { c } from 'utils';
import { Drawer, LinearProgress } from '@mui/material';
import Iframe from 'react-iframe';

enum TasksActions {
  AddToHsrm = 'add_appointment_to_hsrm',
  CancelInHsrm = 'cancel_appointment_in_hsrm',
  UpdateInHsrm = 'update_appointment_in_hsrm',
  CompleteInHsrm = 'complete_appointment_in_hsrm',
  CopySoapNoteToHsrm = 'copy_soap_to_hsrm',
  CopyDocumentsToHsrmForExpiredReferral = 'copy_documents_to_hsrm_for_expired_referral',
  RfsToHsrm = 'copy_rfs_to_hsrm',
  RevocationToHsrm = 'copy_referral_revocation_to_hsrm',
  CopyEvaluationToHsrm = 'copy_evaluation_to_hsrm',
}

enum ReviewTypes {
  Appointment = 'APPOINTMENT',
  ServiceRequest = 'SERVICE REQUEST',
}

enum MilestoneAppt {
  First = 'FIRST',
  Mid = 'MID',
  Final = 'FINAL',
}

const appointmentActionLabel: Record<TasksActions, string> = {
  [TasksActions.AddToHsrm]: 'Add Appointment to HSRM',
  [TasksActions.CancelInHsrm]: 'Copy Cancellation to HSRM',
  [TasksActions.UpdateInHsrm]: 'Update Appointment in HSRM',
  [TasksActions.CompleteInHsrm]: 'Complete Appointment in HSRM',
  [TasksActions.CopySoapNoteToHsrm]: 'Copy Appointment to HSRM',
  [TasksActions.CopyDocumentsToHsrmForExpiredReferral]: 'Mark Referral as Expired',
  [TasksActions.RfsToHsrm]: 'Copy RFS to HSRM',
  [TasksActions.RevocationToHsrm]: 'Copy Revocation to HSRM',
  [TasksActions.CopyEvaluationToHsrm]: 'Copy Evaluation to HSRM',
};

const completedByAttributes: Record<string, string> = {
  [TasksActions.AddToHsrm]: 'add_appointment_completed_by',
  [TasksActions.CancelInHsrm]: 'cancel_appointment_completed_by',
  [TasksActions.UpdateInHsrm]: 'update_appointment_completed_by',
  [TasksActions.CompleteInHsrm]: 'complete_appointment_completed_by',
};

const defaultFilters = {
  test_data: false,
};

const defaultSmartViewFilters = {
  actions: [
    TasksActions.AddToHsrm,
    TasksActions.CancelInHsrm,
    TasksActions.UpdateInHsrm,
    TasksActions.CompleteInHsrm,
    TasksActions.CopySoapNoteToHsrm,
    TasksActions.RfsToHsrm,
  ],
};

export default function TasksV2() {
  const isTestDataCreator = useIsTestDataCreator();

  const service = useZccService<ZccTaskType>(ZCCApi.fetchTasksItems, {
    initialParameters: {
      filters: defaultFilters,
    },
    paramTypes: {
      test_data: 'boolean',
    },
    transformRequest: (params) => {
      if ((!isTestDataCreator && params.filters) || params.filters?.test_data === false) {
        delete params.filters.test_data;
      }
      return params;
    },
  });

  const { token } = useAuth();

  const [smartViewReferralNumber, setSmartViewReferralNumber] = useState<string | null>(null);
  const [disableFilter, setDisableFilter] = useState(false);
  const [open, setOpen] = useState<boolean>(false);
  const [activeRowData, setActiveRowData] = useState<any>(null);
  const [loadingIframe, setLoadingIframe] = useState(false);
  const [openOGAppointmentId, setOpenOGAppointmentId] = useState<string>();
  // const [highlightedRowId, setHighlightedRowId] = useState<string | null | undefined>();
  const { openModal, openAlert } = useUIStore();

  const debouncedSetFilters = useDebouncedCallback((v) => {
    setDisableFilter(!!v);
    service.setFilters(v ? { referral: v, ...defaultSmartViewFilters } : {}, v ? { date: 'desc' } : {});
  }, 500);

  const rowClicked = (row: any) => {
    const { id } = row || {};
    if (open && activeRowData && id === activeRowData?.id) setOpen(false);
    else {
      setOpen(true);
      setActiveRowData(row);
    }
  };

  const sortChangeHandler = (sortModel: any) => {
    const sortBy: ZCCSortByParameters = {};
    sortModel?.forEach(({ field, sort }: any) => {
      sortBy[field] = sort;
    });
    if (!isEqual(service.parameters.sortBy, sortBy)) {
      service.setSortBy(sortBy);
    }
  };

  // const highlightRow = (rowId: string) => {
  //   setHighlightedRowId(rowId);
  //   setTimeout(() => setHighlightedRowId(null), 5000);
  // };

  // Adding appointments and notes
  const rows = useMemo(() => {
    const r = [
      ...(service.data || []).map((item: ZccTaskType, index: number) => {
        const {
          _id,
          og_appointment_id, // used for appt flagged for review (until moved to NG)
          milestone_appointment,
          patient_name,
          submitted_by,
          date,
          date_submitted,
          review_type,
          status,
          actions,
          session,
          referral,
          impacted_area,
          va_facility,
          va_facility_name,
          hold_until_date,
        } = item;

        return {
          _id,
          id: index,
          entity_id: _id + '',
          milestone_appointment,
          og_appointment_id,
          patient_name,
          submitted_by,
          date,
          date_submitted,
          review_type,
          session,
          referral,
          impacted_area,
          status,
          actions,
          va_facility,
          va_facility_name,
          hold_until_date,
          data: item,
        };
      }),
    ];
    return r;
  }, [service.data]);

  // Actions
  const openAppointmentModal = (
    ogAppointmentId: string,
    entityId: string,
    toAdd = false,
    actionLabel: string,
    completedByAttribute: string
  ) => {
    if (ogAppointmentId && entityId) {
      openModal({
        id: 'copy-appointment-to-hsrm-v2',
        props: { ogAppointmentId, entityId, toAdd, title: actionLabel, completedByAttribute },
        callback: (response: any) => {
          if (response) {
            service.refresh();
          }
        },
      });
    } else {
      alert(
        `OG Appointment ID is missing for this record, which prevents from opening the review modal. (Procedure ID: ${entityId})`
      );
    }
  };

  const openSoapNoteModal = (ogAppointmentId: string, entityId: string, isSoapNote: boolean) => {
    if (ogAppointmentId && entityId) {
      openModal({
        id: 'copy-soap-note-or-evaluation-to-hsrm',
        props: { ogAppointmentId, entityId, isSoapNote },
        callback: (response: any) => {
          if (response) {
            service.refresh();
          }
        },
      });
    } else {
      alert(
        `OG Appointment ID is missing for this record, which prevents from opening the review modal. (Composition ID: ${entityId})`
      );
    }
  };

  const openRfsModal = (rfsId: string) => {
    if (rfsId) {
      openModal({
        id: 'copy-request-to-hsrm',
        props: { requestId: rfsId },
        callback: (response: any) => {
          if (response) {
            service.refresh();
          }
        },
      });
    } else {
      alert('Request ID is missing for this record, which prevents from opening the review modal.');
    }
  };

  const openRevocationModal = (serviceRequestId: string, isExpired?: boolean) => {
    if (serviceRequestId) {
      openModal({
        id: 'copy-revocation-to-hsrm',
        props: { serviceRequestId, isExpired },
        callback: (response: any) => {
          if (response) {
            service.refresh();
          }
        },
      });
    } else {
      alert('Service Request ID is missing for this record, which prevents from opening the review modal.');
    }
  };

  const unSnoozeRFS = async (requestId: string) => {
    try {
      await ZrefApi.updateRequest(requestId, {
        hold_until_date: null,
      });
      openAlert({
        title: 'RFS Un-Snoozed',
      });
      service.refresh();
    } catch (e) {
      openAlert({
        title: 'Failed to un-snooze the RFS',
      });
      console.error(e);
    }
  };

  const openSnoozeRFSModal = async (requestId: string) => {
    openModal({
      id: 'pick-calendar-date',
      props: {
        title: 'Snooze RFS Until...',
        description: `We'll re-surface this RFS in the Task list for uploading on this date.`,
        buttonTitle: 'Snooze RFS',
      },
      callback: async (date?: Date) => {
        if (date) {
          try {
            await ZrefApi.updateRequest(requestId, {
              hold_until_date: moment(date).format('YYYY-MM-DD'),
            });

            openAlert({
              title: `We’ll re-surface this RFS in the Tasks list on ${moment(date).format('MMMM DD, YYYY')}.`,
            });
            service.refresh();
          } catch (e) {
            openAlert({
              title: 'Failed to snooze this RFS',
            });
            console.error(e);
          }
        }
      },
    });
  };

  const accessToken = useMemo(() => token?.access?.replace('Bearer', '')?.trim() || '', [token?.access]);

  const cmsUrl = useMemo(() => {
    let url = `${process.env.REACT_APP_OG_API}/cms/${c.SERVICES.ID.MEDICAL}/appointment`;
    if (openOGAppointmentId) {
      url += `/listing/one/${openOGAppointmentId}`;
    }
    // Hide header, chat, and footer
    url += `?_header=0&_chat=0&_footer=0&action_by_service=ZCC&bearer_token=${accessToken}`;
    return url;
  }, [openOGAppointmentId, accessToken]);

  const onCloseDrawer = useCallback(() => {
    setOpenOGAppointmentId(undefined);
  }, []);

  const columns = useMemo(
    () => [
      { id: '_id', label: 'ID', width: 92 },
      {
        id: 'og_appointment_id',
        label: 'OG appt ID',
        width: 120,
        renderCell: ({ value }: any) => {
          return (
            <div className={styles.cellWrapper}>
              <span className={styles.ellipsisText}>{value}</span>
              <span>
                <Icon
                  color='actionable-green'
                  name='new-window'
                  size={16}
                  onClick={(e) => {
                    e?.stopPropagation();
                    setOpenOGAppointmentId(value);
                    setLoadingIframe(true);
                  }}
                />
              </span>
            </div>
          );
        },
      },
      { id: 'patient_name', label: 'Patient Name', width: 230 },
      {
        id: 'date',
        label: 'Appt Date/Time',
        width: 210,
        getter: ({ value, row }: any) => {
          return value
            ? moment(value)
                .tz(row.timezone || getLocalTimezone())
                .format('MMM DD, YYYY hh:mm A z')
            : '';
        },
      },
      { id: 'date_submitted', label: 'Date Submitted', width: 200, type: 'moment' },
      { id: 'submitted_by', label: 'Submitted By', width: 200 },
      { id: 'va_facility', label: 'VA Facility ID', width: 130 },
      {
        id: 'va_facility_name',
        label: 'VA Facility Name',
        width: 180,
        renderCell: ({ value }: any) => {
          return (
            <Tooltip title={value} arrow placement='top'>
              <span className={styles.ellipsisText}>{value}</span>
            </Tooltip>
          );
        },
      },
      { id: 'milestone_appointment', label: 'Milestone Appt.', width: 120 },
      { id: 'session', label: 'Session', width: 110 },
      { id: 'referral', label: 'Referral', width: 180 },
      {
        id: 'status',
        label: 'Status',
        width: 120,
        renderCell: ({ value, row }) => (
          <div>
            <div className={styles.status}>
              {row?.data?.hold_until_date && <Icon name='calendar-appointment-pause' size={16} />}
              {row?.data?.hold_until_date ? 'SNOOZED' : value}
            </div>
            {row?.data?.hold_until_date && <div>until {moment(row.data.hold_until_date).format('MM/DD/YYYY')}</div>}
          </div>
        ),
      },
      {
        id: 'action',
        label: 'Task',
        width: 500,
        sortable: false,
        disableColumnMenu: true,
        renderCell: ({ row }: any) => {
          const actionsList = [];
          const menuActionsList = [];
          const action: TasksActions = row?.data?.actions?.find(
            (a: TasksActions) =>
              a === TasksActions.AddToHsrm ||
              a === TasksActions.CancelInHsrm ||
              a === TasksActions.UpdateInHsrm ||
              a === TasksActions.CompleteInHsrm
          );
          if (action) {
            actionsList.push(
              <Button
                state='primary'
                variant='outlined'
                onClick={(e: MouseEvent) => {
                  openAppointmentModal(
                    row?.og_appointment_id,
                    row?.entity_id,
                    action === TasksActions.AddToHsrm || action === TasksActions.UpdateInHsrm,
                    appointmentActionLabel[action],
                    completedByAttributes[action]
                  );
                  e.stopPropagation();
                }}>
                {appointmentActionLabel[action]}
              </Button>
            );
            menuActionsList.push({
              icon: action === TasksActions.CancelInHsrm ? 'x-circle' : 'approve',
              label: appointmentActionLabel[action],
              onClick: () => {
                openAppointmentModal(
                  row?.og_appointment_id,
                  row?.entity_id,
                  action === TasksActions.AddToHsrm || action === TasksActions.UpdateInHsrm,
                  appointmentActionLabel[action],
                  completedByAttributes[action]
                );
              },
            });
          }
          const copyToHsrmActions: TasksActions = row?.data?.actions?.find(
            (a: TasksActions) => a === TasksActions.CopySoapNoteToHsrm || a === TasksActions.CopyEvaluationToHsrm
          );
          if (copyToHsrmActions) {
            actionsList.push(
              <Button
                state='primary'
                variant='outlined'
                onClick={(e: MouseEvent) => {
                  openSoapNoteModal(
                    row?.og_appointment_id,
                    row?.entity_id,
                    copyToHsrmActions === TasksActions.CopySoapNoteToHsrm
                  );
                  e.stopPropagation();
                }}>
                {appointmentActionLabel[copyToHsrmActions]}
              </Button>
            );
            menuActionsList.push({
              icon: 'checkmark-circle',
              label: appointmentActionLabel[copyToHsrmActions],
              onClick: () => {
                openSoapNoteModal(
                  row?.og_appointment_id,
                  row?.entity_id,
                  copyToHsrmActions === TasksActions.CopySoapNoteToHsrm
                );
              },
            });
          }
          if (row?.data?.actions?.includes(TasksActions.RfsToHsrm)) {
            if (!row?.hold_until_date) {
              actionsList.push(
                <Button
                  state='primary'
                  variant='outlined'
                  onClick={(e: MouseEvent) => {
                    openRfsModal(row?.entity_id);
                    e.stopPropagation();
                  }}>
                  {appointmentActionLabel[TasksActions.RfsToHsrm]}
                </Button>
              );
            }
            if (row?.hold_until_date) {
              menuActionsList.push({
                icon: 'calendar-checkmark',
                label: 'Un-Snooze RFS',
                onClick: () => unSnoozeRFS(row.entity_id),
              });
            } else {
              menuActionsList.push({
                icon: 'calendar-appointment-pause',
                label: 'Snooze RFS',
                onClick: () => openSnoozeRFSModal(row.entity_id),
              });
            }
            menuActionsList.push({
              icon: 'checkmark-circle',
              label: appointmentActionLabel[TasksActions.RfsToHsrm],
              onClick: () => {
                openRfsModal(row?.entity_id);
              },
            });
          }
          const revocationAction: TasksActions = row?.data?.actions?.find(
            (a: TasksActions) =>
              a === TasksActions.RevocationToHsrm || a === TasksActions.CopyDocumentsToHsrmForExpiredReferral
          );
          if (revocationAction) {
            actionsList.push(
              <Button
                state='primary'
                variant='outlined'
                onClick={(e: MouseEvent) => {
                  openRevocationModal(
                    row?.entity_id,
                    revocationAction === TasksActions.CopyDocumentsToHsrmForExpiredReferral
                  );
                  e.stopPropagation();
                }}>
                {appointmentActionLabel[revocationAction]}
              </Button>
            );
            menuActionsList.push({
              icon: 'checkmark-circle',
              label: appointmentActionLabel[revocationAction],
              onClick: () => {
                openRevocationModal(
                  row?.entity_id,
                  revocationAction === TasksActions.CopyDocumentsToHsrmForExpiredReferral
                );
              },
            });
          }
          return (
            <div className={styles.actions}>
              {actionsList}
              {menuActionsList.length > 0 && (
                <div>
                  <IconMenu items={menuActionsList} />
                </div>
              )}
            </div>
          );
        },
      },
    ],
    []
  );

  let sideContent = null;
  if (activeRowData) {
    if (activeRowData?.review_type === ReviewTypes.Appointment) {
      sideContent = (
        <AppointmentSidePanel
          open={open}
          onClose={() => setOpen(false)}
          ogAppointmentId={activeRowData?.og_appointment_id}
          ngAppointmentId={activeRowData?.entity_id}
        />
      );
    }
    if (activeRowData?.review_type === ReviewTypes.ServiceRequest) {
      sideContent = (
        <ServiceRequestSidePanel open={open} onClose={() => setOpen(false)} id={activeRowData?.entity_id} />
      );
    }
  }

  const handleSmartViewReferralNumber = (e: any) => {
    const newValue = e.target.value;
    setSmartViewReferralNumber(newValue);
    debouncedSetFilters.callback(newValue);
  };

  return (
    <SectionLayout
      alert={service.error}
      title='Tasks'
      sideContent={sideContent}
      titleContent={
        <div className={styles.titleContent}>
          <span>Referral Task Smartview:</span>
          <TextField label='Referral #' value={smartViewReferralNumber} onChange={handleSmartViewReferralNumber} />
        </div>
      }>
      <div className={styles.tableHeader}>
        <FilterButtonV3
          disabled={disableFilter}
          initialValue={service.parameters.filters}
          defaultFilters={defaultFilters}
          onChange={(newFilters: any) => {
            service.setFilters(newFilters);
          }}
          searchTestDataOnly={isTestDataCreator && service.parameters.filters?.test_data}
          searches={[
            {
              id: 'patient_name',
              label: 'Patient Name',
              type: 'auto-complete-search',
              fetchItems: ZCCApi.searchPatientName,
            },
            { id: 'referral', label: 'Referral #', type: 'text' },
            {
              id: 'submitted_by',
              label: 'Submitted By',
              type: 'auto-complete-search',
              fetchItems: ZCCApi.searchPractitionerName,
            },
          ]}
          filters={[
            { id: '_id', label: 'ID', type: 'text' },
            { id: 'og_appointment_id', label: 'OG Appointment ID', type: 'text' },
            { id: 'va_facility', label: 'VA Facility ID', type: 'text' },
            { id: 'va_facility_name', label: 'VA Facility Name', type: 'text' },
            { id: 'session', label: 'Session', type: 'text' },
            {
              id: 'status',
              label: 'Status',
              type: 'select-multiple',
              options: getOptionsFromEnum(
                {
                  ...AppointmentStatusType,
                  ...ServiceRequestStatusType,
                  ...CompositionStatusType,
                  ...RequestStatusType,
                },
                true
              ),
            },
            { id: 'hold_until_date', label: 'Hold Until', type: 'date', size: 'half' },
            {
              id: 'milestone_appointment',
              label: 'Milestone Appointment',
              type: 'select',
              options: getOptionsFromEnum(MilestoneAppt),
            },
            {
              id: 'actions',
              label: 'Task Type',
              type: 'select-multiple',
              options: [
                { label: 'Add Appointment to HSRM', value: TasksActions.AddToHsrm },
                { label: 'Copy Cancellation to HSRM', value: TasksActions.CancelInHsrm },
                { label: 'Update Appointment in HSRM', value: TasksActions.UpdateInHsrm },
                { label: 'Complete Appointment to HSRM', value: TasksActions.CompleteInHsrm },
                { label: 'Copy Appointment to HSRM', value: TasksActions.CopySoapNoteToHsrm },
                { label: 'Copy RFS to HSRM', value: TasksActions.RfsToHsrm },
                { label: 'Copy Referral Revocation to HSRM', value: TasksActions.RevocationToHsrm },
                {
                  label: 'Copy Expired Referral Documents to HSRM',
                  value: TasksActions.CopyDocumentsToHsrmForExpiredReferral,
                },
                {
                  label: 'Copy Evaluation To HSRM',
                  value: TasksActions.CopyEvaluationToHsrm,
                },
              ],
            },
            ...(isTestDataCreator
              ? [
                  {
                    id: 'test_data',
                    label: 'Test Data',
                    type: 'segments',
                    permanent: true,
                    options: [
                      { label: 'Yes', value: true },
                      { label: 'No', value: false },
                    ],
                  } as FilterType,
                ]
              : []),
          ]}
        />
      </div>
      <Table
        className={styles.table}
        loading={service.loading}
        name={`${rows.length} Items Need Review`}
        columns={columns}
        rows={rows}
        onRowClick={(row: any) => rowClicked(row)}
        activeRowId={open ? activeRowData?.rowId : undefined}
        // highlightedRowId={highlightedRowId}
        page={service.parameters.page}
        pageSize={service.parameters.pageSize}
        serverPagination
        serverRowCount={service.total}
        onPageChange={(page: any) => service.setPage(page)}
        onPageSizeChange={(pageSize: any) => service.setPageSize(pageSize)}
        rowsPerPageOptions={[100]}
        sortModel={service.sortModel}
        onSortModelChange={sortChangeHandler}
      />
      <Drawer
        anchor={'bottom'}
        open={!!openOGAppointmentId}
        onClose={() => onCloseDrawer()}
        PaperProps={{ sx: { width: '95%', margin: 'auto', borderRadius: 8 } }}>
        <div>
          {loadingIframe && <LinearProgress className={styles.load} />}
          <Icon className={styles.drawerClose} name='x' size={24} onClick={() => onCloseDrawer()} />
          <Iframe
            url={cmsUrl}
            id={openOGAppointmentId}
            width='100%'
            height='500'
            overflow='auto'
            display='block'
            onLoad={() => setLoadingIframe(false)}
          />
        </div>
      </Drawer>
    </SectionLayout>
  );
}
